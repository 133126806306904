.pagination {
    display: inline-block;
    /* display: flex; */
}
  
.pagination .page {
    color: black;
    float: left;
    padding: 0px 6px;
    text-decoration: none;
}