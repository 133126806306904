
/*
Adjust column size to gice space for additional filter
*/
.filters.filter-dtr {width: 100%; float: none;margin:0; padding: 0 20px}
.filters.filter-dtr .filter-button {
    width: 15%;
    flex: 15% 1;
    text-align: right;
}
.filters.filter-dtr .date-range {
    width: 33% !important;
    flex: 0 1 34%;
}

.dtr-summary-table {
    width: 97%;
    margin: 0 auto;
}

.btns{padding-top: 32px}
.dtr-table .table td,.dtr-table .table th,
.table-responsive .table td,.table-responsive  .table th,
{
    border: 1px solid #dee2e6;
}
.dtrSummary thead th{font-size: 12px; width: 65px}
.dtrSummary tbody td,.dtrSummary thead th{border:1px solid #ccc;text-align: center;padding: 5px; width: 50px; vertical-align: middle;font-size: 12px}

.dtrSummary thead th.RD{background-color: #868686 !important;color: white;}
.dtrSummary thead th.SH{background-color: #ffc247 !important;color: white;}
.dtrSummary thead th.LH{background-color: #3853ca !important;color: white;}
.dtrSummary thead th.th-dept{width: 100px}
.dtrSummary thead th.th-name{width: 100px}

.export-drop-down{
    display: inline;
}

.dropdown-item:focus{
    background-color:#82af13  !important;    
    color: white;
}