.icon-column {
    width: 50px;
    border-right: 1px solid white !important;
    text-align: center;
    padding-top: 10px;
}

.details-column {
    width: 30%;
    border-left: 1px solid white !important;
    border-right: 1px solid white !important;
}
.note-column {
    width: 65%;
    border-left: 1px solid white !important;
    min-height: 50px;
    vertical-align: middle;
}
.note-column small{
    display: none;

}
.time-off-icon {
    width: 50px;
    height: 50px;
}
.leave-row {
    border-bottom: 1px solid #cccccc !important;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-left: 10px !important;
    margin-right: 10px !important;
}
.leave-row .fa-icon{font-size: 2rem;}
.no-leaves-row{
    text-align: center;
    margin-top: 40px;
}

.time-off-date {
    font-size: 1.5;
}


.fa-icon {
    color: #82af13;
    font-size: 50px;
}
.profile-content .row{padding:10px 0;}
.profile-content .input-group .year-dropdown{margin: 0 !important; padding: 0}
.year-dropdown{padding: 0; margin: 0}
.profile-header{width: 80%; margin-left: 25px}
.profile-header .picture {
    float: left;
    display: inline-block;
    overflow: hidden;
    flex: none;
    max-width: 123px;
    padding: 0 !important;
    margin: 0 15px 0 0 !important;
}

.profile-header img {
    margin-top: 15px;
    width: 115px;
    height: 115px;
    border-radius: 50%;
    border:1px solid #ccc;
}

.profile-header .information{float: left; position: relative;top:40px;}
.profile-header input{border:0 !important; background-color: transparent !important;}

.profile-tabs{position: absolute;top:-28px; right: 5px}
.profile-tabs .nav-tabs{border: 0 !important;}
.profile-tabs a{padding:5px 10px !important;  margin-bottom: 0 !important; border:0 !important; background: #ccc; color:#333 !important; margin-right: 5px;}
.profile-tabs a.active {background-color: #82af13  !important; color: #fff !important;}
.profile-tabs button.active a{color:#82af13 !important;}
.report-navigator a{padding:5px 10px !important;}
.card-header{background: transparent !important;}
.user_form .row {margin-top: 15px; margin-bottom: 15px }
.user_form .year-dropdown{padding: 0}
.profile-content .navigator-bar {
    margin-bottom: -3px;}
.profile-content .navigator-bar .nav-tabs #uncontrolled-tab-example-tab-day{display: none}
.profile-content .card-header{display: none}
@media only screen and (max-width: 650px) {
.profile-header .picture{display: none !important;}
.profile-header .information {
    float: none;
    top: 0px;
}
.profile-content .card-header{display: none;}
 .profile-content .card-body{
    margin-top: 36px;
}
.leaves-col,
.leave-credits .col {
    padding:0 5px!important;
}

.timeoff-col {margin-top: 30px;}
.icon-column {
    width: 50px;

}

.details-column {
    width: 80%;

}
.note-column {
    width: 100%;
}
.note-column small{
    display: block;
}
}