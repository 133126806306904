.page-not-found-box {
    text-align: center;
    background: #f4f4f4;
    -webkit-box-shadow: 2px 2px 10px 2px #404040; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    2px 2px 10px 2px #404040; /* Firefox 3.5 - 3.6 */
    box-shadow:         2px 2px 10px 2px #404040; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

.page-not-found-code {
    font-size: 90px;
}

.page-not-found-oops {
    font-size: 50px;
}
.page-not-found-message {
    font-size: 20px;
}