/*
 * Dashboard css File
*/
body{font-size: 12px !important}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{text-transform: uppercase; color: #333 !important;}
h1,.h1{font-size: 1.5em !important}
h2,.h2{font-size: 1.25em !important}
h3,.h3{font-size: 1.125em !important}
h4,.h4{font-size: .1em !important}
h5,.h5{font-size: .875em !important}
h6,.h6{font-size: .75em !important}
.nav-link{padding: .5em 0 !important}
.dashboard .row{margin: 0 !important;}
.dashboard > .row > .col-lg-4 {padding: 0 !important}
.dashboard .col-6 > div{margin-bottom: 20px; min-height: 100px}
.dashboard .card-body{box-shadow: none !important;padding: 0px 0 !important; border:0 !important;}
.card-header {background: transparent !important;}
.alert-pop-up{z-index:  100; width: 80% !important; max-width: 400px;top:25%;}
.alert-heading {text-transform: initial !important;}
.page-title{margin-left:20px;}
.team-request-page .col-lg-12{padding: 0 !important; margin:0 !important;}
.pd30{margin:0;padding: 30px}
.fadeOut{display: none;}

.dashboard.client{background: #fff}
.dashboard.client .row .select-dept{padding: 20px}
.content-table {
    width: 95%;
    overflow-x: auto;
    margin: 0 0 0 25px;
}
.content-table table thead th{text-align: left; vertical-align: middle;background: #82af13 !important; color: #fff !important;padding:.5rem !important; font-size:12px;}
.content-table table tbody td{text-align: left; vertical-align: middle;border:1px solid #ddd !important;padding:.5rem !important; font-size:12px;}

.birthday-anniv .content-table. table thead th{text-align: left; vertical-align: middle;background: transparent !important; color: #333 !important}
.content-table.bdr0{margin:0px; width: 100%; border:0;}
.content-table.bdr0 table thead th{text-align: left; vertical-align: middle;border-top:0 !important; border-left:0 !important; border-right:0 !important;}
.content-table.bdr0 table tbody td{background: #fff !important;text-align: left; vertical-align: middle;border-top:0 !important; border-left:0 !important; border-right:0 !important;}
.content-table.bdr0 table tbody td.date{width: 100px}
.dashboard > .row > div > .row,
.dashboard > .row > div > .row > div >.row{margin-top: 10px !important}
.dashboard .content-table table{margin:0px auto; width: 100%; border:0 !important; background: #fff}
.dashboard .card-header{ border-bottom: 5px solid #0097A7 !important; width: 100%; background: transparent !important; }
.team-attendance .content-table{margin:0px; width: 100%; border:0 !important;}
.team-attendance .content-table table thead th{background:#fff !important; color:#333 !important;text-align: left; vertical-align: middle;border-top:0 !important; border-left:0 !important; border-right:0 !important;}
.team-attendance .content-table table tbody td{text-align: left; vertical-align: middle;border-top:0 !important; border-left:0 !important; border-right:0 !important;}

.card {
    border: 0 !important;
    border-radius: 0  !important;
    box-shadow: none;
}
.card-header {
    padding: 0  !important;
}
 .card-title {
    background-color: transparent ;
    color: #000 !important;
    padding: 0;
    text-align: center;
    font-size: 1.5em;
    font-weight: 500;
}
.block{background: #fff;
    padding: 20px;
    border-top: 5px solid #82af13;
    margin-bottom: 20px;
   }

.dashboard h3{font-size: 1rem !important}
.block h3{margin-bottom: 20px;}
.block img{max-height: 312px; width: 100%}
.table thead th {font-weight: normal !important; text-transform: uppercase; font-weight: 500 !important;  }
.table-responsive .table thead th, .table-responsive .table tbody td{border:1px solid #ddd;}
.table thead th.dtr-date{ width: 5%; text-align: center;}
.table thead th.dtr-status{width: 13%; text-align: center;  font-size: inherit}
.table thead th.dtr-item{width: 6%; text-align: center;}
.table thead th.dtr-log{width: 7%; text-align: center;}
.table thead th.dtr-log .row{padding: 10px 0;  font-size: inherit;}
.table thead th.dtr-schedule{width: 13%; text-align: center;}
.table thead th.dtr-requests{width: 14%; text-align: center;}
.table thead th.dtr-actions{width: 5%; text-align: center;}
.table tbody td{ font-size: inherit; vertical-align: middle;}
.requests-list{text-align: left;}
.requests-list ul{padding: 0 !important}
.requests-list li{
     font-size: inherit;
    display: block;
    margin: 5px 0;
}
.btn{font-size:12px !important;}
.table td.dtr-actions .btn-primary{background: transparent !important; border: 0 !important }
.table td.dtr-actions .btn-primary .fa{color:#82af13!important; font-size: 24px;}
.table td.dtr-schedule .row,
.requests-list li .row{
    margin: 5px 0;
}

.requests-list span.circ{
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 50%;
}
.requests-list .Pending span.circ{
    background: #f1c40f
}
.requests-list .Approved span.circ{
    background: #81b014
}
.requests-list .Canceled span.circ{
    background: #717171
}
.requests-list .Declined span.circ{
    background: #dc3545
}
.requests-list .Pending span{color: #f1c40f}
.requests-list .Approved span{color: #81b014}
.requests-list .Canceled span{color: #717171;}
.requests-list .Declined span{color: #dc3545}
.form-control{color: #666 !important;}
.input-group-text{font-size:12px !important; padding: 3px 10px !important}
.col-btn{padding-top: 30px}
.date-range.row{padding-top: 30px;}
.emp-status{ font-size: inherit;}
td.actions .fa{font-size: 18px}
.pagination .page button{ font-size: inherit}
.filters{margin-top: 30px}
.evbuddy{background:  #fff; padding: 20px; max-height: 500px; overflow-y:auto;}

.evbuddy a{ text-decoration: none; color: #333 !important; font-size: .875em}
.btn{padding: 5px 10px !important;}
a.btn-primary{color: #fff !important;}
.card-header .back-button{position: fixed; top: 7%; right:2%;z-index: 1000}
.team-summary .table{width: 100% !important}
.team-summary .card-body{padding: 40px 0 !important}
.team-summary table thead td{background: #eee}

.team-summary table thead td .desc{ text-align: center; font-size: .875rem  !important;}
.team-summary table thead td .total{font-size: 2rem; text-align: center;}
.team-summary table tbody td,.team-summary table tbody td div{text-align: center;}
.team-summary table tbody td .total{font-size: 1.5rem; text-align: center; line-height: 150%}
.team-summary table tbody td .total.up{color:#81b014 !important;}
.team-summary table tbody td .total.down{color:#dc3545 !important;}

.team-summary table tbody td h4{ text-align: center; font-size: .75rem !important;}
.team-summary table tbody td h5{ text-align: center; font-size: .65rem !important;}

.team-attendance .content-table table tbody td:nth-child(2){font-size: .75rem; width: 35%}
.team-attendance .content-table .holiday{color: orange !important}
.team-attendance .content-table{height: 400px; overflow-y: auto}
.team-attendance .content-table td{background: #fff !important}
.team-attendance .content-table tbody{overflow-y: auto;}
.company-announcement .card-body >.row{margin-bottom: 0px !important; background: #fff}
.company-announcement .card-body >.row p{padding:10px;}
.company-announcement .card-body{background: transparent;}


table thead tr th{background: #0097A7  ; color:#fff; font-size: 11px !important; }
table tbody tr td{ font-size: .875em !important}
.dashboard .dtr-table thead th{font-size:.5rem !important;}
.dashboard .dtr-table tbody td{}
.dashboard .dtr-log .justify-content-center{font-size:.75rem !important;}
.dashboard .month.justify-content-center{font-size:.65rem !important;}
.dashboard .dtr-table tbody td.dtr-status{font-size:.75rem !important;}
.dashboard .dtr-table tbody td.dtr-schedule{font-size:.75rem !important}
.dashboard .dtr-notifications .card-header{border:0 !important;}
.dashboard .dtr-notifications .card-body{padding:0 !important;}
.no-notifications{padding: 20px 0;}
.btn-secondary {
    background-color: #6c757d !important;
    border-color: #6c757d !important;
}
@media only screen and (max-width: 650px) {
    .dashboard .col-md-12,.dashboard .col-lg-12{padding: 0 !important ;}
}
table {
    /* border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px; */
  }