.tooglestyle{
    width: 100% !important;
    text-decoration: none !important;
    color: white !important;
    background-image: linear-gradient(to right,#27769d, #92c83e) !important;
    line-height: 24px !important;
    font-size: 16px !important;
}
.mail_link{
    color: #82af13 !important;
}

.referal{
    font-size: 16px !important;
}

.removeborder {

    border: none !important;
}

.form-check-label {
    font-size: 15px !important;
}
.applybtn{
    margin-top: 15px !important;
}