
.spanner{
    position:absolute;
    top: 50%;
    left: 0;
    width: 100%;
    display:block;
    text-align:center;
    height: 300px;
    color: #FFF;
    transform: translateY(-50%);
    z-index: 1000;
}

.overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10001;
    background: rgba(0,0,0,0.5);
}

/* 
.fadeOutLoader{
    opacity:0;
    display:none;
    transition: width 0.25s 0.25s, height 0.25s 0.25s, opacity 0.25s;

}
.fadeInLoader{
    opacity:1;
    display:block;
    transition: width 0.15s, height 0.15s, opacity 0.15s 0.15s;

} */