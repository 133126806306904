.ops-schedule >.row{margin: 0 !important;}
.ops-schedule .bg-blue{background:#d7f3ff !important; color:#000 !important;}
.ops-schedule .bg-yellow{background:#feffd7 !important}
.ops-schedule .bg-orange{background: #ffe9dc !important}
.header_text{
    margin-left: 5.6rem !important;
}
.ops-schedule .no-bullet{
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
}
