

.request-tab {
    position: relative;
    top: 12px;
    left: 21px;
    z-index: 1000;
}
.header_text{
    margin-left: 1.3rem !important;
}
.request-tab .nav-tabs{border: 0 !important;}
.request-tab a{padding: 5px 8px 3px 8px !important; margin-bottom: 0 !important; border:0 !important; background: #ccc; color:#333 !important; margin-right: 3px; font-size: .875em !important}
.request-tab a.active {
     background-color: #82af13  !important; color: #fff !important;
}
.my-team-request .status-filter .request_list_btn{
    background-color:white !important;
    color: black !important;
    font-size: .875em !important;
    margin: 10px;
    border: 1px solid #ccc !important;
    border-radius: 2px !important;
}
.my-team-request .status-filter .request_list_btn.active {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(130, 175, 19, 0.2) !important;
}


.my-team-request .status-filter .request_list_btn .badge{border:1px solid #ccc;}
.my-team-request .status-filter .request_list_btn .badge-light{background: #eee;}
.filters.myrequest-filter {margin-top:20px; width: 50%; margin-left: -5px; float: none;}
.filters .date-range label,.bulk-action .form-group label {
    float: left;
    font-size: 10px;
    padding: 9px 5px 8px;
    text-transform: uppercase;
    background: #ccc;
}
.filters .col{padding: 0px !important; margin:0 10px 0 0 !important;}
.filters .date-range > div > div{width: 36%; float: left; margin-right: 5px}
.filters .date-range {
    width: 38% !important;
    flex: 0 1 38%;
    margin-left: 0% !important;
}
.filters.myrequest-filter .date-range {
    width: 80% !important;
    flex: 0 80%;
}
.filters .dept {
    width: 27%;
    flex: 10%;
}
.filters .search-name{width: 25%;flex: 10%;}
.filters .filter-button{ width: 15%;flex: 5%; }
.filters select, .filters input[type="textfield"]{font-size:1rem;}
.bulk-action .form-group >div {float: left; margin-right: 10px}
.badge-success{background-color: #82af13 }
.request_list .status div > span { color:#000; display: inline-block;padding: 3px 5px; font-weight: normal !important;}
.request_list .status .Approved > span { background-color: #e2e3c8  !important; }
.request_list .status .Declined > span { background-color: #ffd3d3  !important; }
.request_list .status .Canceled > span { background-color: #ccc  !important;}
.request_list .status .Pending > span { background-color: #fbfabe  !important; }

.request_list .status div > span > span{ height: 5px; width: 5px; border-radius: 50%; display: inline-block; margin-right: 5px}
.request_list .status .Approved > span > span{ background-color: #82af13  !important; border-color: #82af13; }
.request_list .status .Declined > span > span{ background-color: #ff0000  !important; border-color: #ff0000; }
.request_list .status .Canceled > span > span{ background-color: #888  !important; border-color: #999; }
.request_list .status .Pending > span > span{ background-color: #ffb400  !important; border-color: #ffb400; }


.denied{
    background-color: red !important;
    color: white !important;
}

.canceled{
    background-color:gray !important;
    color: white !important;
}

.approved, .nav-link .page-item.active .page-link{
    background-color: #82af13  !important;
    border-color: #82af13;
    color: white !important;
}

.pending{
    background-color: #ffc107 !important;
    color: white !important;
}

.pagination .nav-link{
    padding: 0px !important;
    border-radius: 0px !important;
}


.pagination_btn{
    margin-left: -1px;
    line-height: 1.25;
    color: #000000 !important;
    background-color: #fff !important;
    border: 1px solid #dee2e6;
    border-radius: 0px !important;
}



.pagination .active{
    background-color: #82af13 !important;
    color: white !important;
}

.fa-check-circle{
    color: #82af13 !important;
}

.fa-times-circle{
    color: red  !important;
}

.btn.display-block{
    display: block !important   ;
    width: 100px!important;
    font-size:12px;
    padding:8px 0;
}

.input-feedback{
    color: red !important;
}
.form-control{font-size: inherit !important}
.react-datepicker-wrapper {
    display: block;
}

@media only screen and (max-width: 650px) {
.bulk-action .form-group label, .filters .date-range label {
    font-size: .5rem;
}
.btn.display-block {
    font-size: .75rem;
    width: 90px!important;
}
.filters.myrequest-filter {margin:0; width: 100%; float: none;}
.filters.myrequest-filter .date-range {
    width: 75% !important;
    flex: 0 75%;
}
.form-control{font-size:.75rem !important}
.filters .date-range{flex: 0 1 100%;}
.filters .date-range > div > div{width: 31%; float: left; margin-right: 5px}
.filters .dept{width: 27%}
.filters .search-name{width: 25%}
.filters .btn.display-block{width:52px !important; overflow: hidden; word-break: break-all; height: 38px}
.request-tab {
    top: 18px;
    left: 4px;
}
.request-tab .nav-tabs a{
    display: none
}
.request-tab a{padding: 5px 10px}
.request-tab .nav-tabs a {
    font-size: .75rem;
    margin: 0 1px 0 1px;
    padding: 3px 7px;
}
.filters.myrequest-filter .btn
 {
    height: 34px;
    width: 38px;
    overflow:hidden;
}

}

.showall_checkbox{
    margin: 4px 0 0;
    line-height: normal;
    width: 20px;
    height: 20px;
    }
.showall_text{
    font-size: 15px;
    font-weight:500;
}
