.birthday-anniv .card-body{
    max-height: 400px;
    overflow: hidden;
    overflow-y: scroll;
} 
.celebration_notfound{
    text-align: center !important;
    padding: 172px 94px !important;
    text-decoration: none !important;
    font-size: 17px !important;
    font-weight: bold !important;
    color: #07335e !important;
}

.celebration .content-table.bdr0 table tbody td {
    background: transparent !important;
    font-size: 13px !important;
}
/* 
tr{
    border: 0;
    display: block;
    margin: 5px;
    border-radius: 7px;
    background: #ffffff !important;
} */
