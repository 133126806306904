.holiday{
    color: #fd7e14 !important
}

.on-leave{
    color: #0033cc !important;
}

.no_time_logs, .rest_day{
    color: #696969!important;
}