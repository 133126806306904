.nav-puncher{
    /* background-color: #6A6A6A; */
    padding-left: 10px;
    padding-right: 10px;
    /* padding-top: 3px; */
}
.nav-clock:hover{
    background-color: #6a6a6a87;
    border-radius: 3px;
    transition: 0.3s;
}


.nav-clock-button{
    font-size: 20px !important;
  }
  
  /* .somethin */


.early-out-btn{
    /* margin-left: 10px; */
    margin-right: 10px;
}

.early-out-btn-cancel{
    /* margin-left: 10px; */
    margin-right: 10px;
    background-color: black !important;
}


.dropdown-toggle::after {
    display: none !important; 
  }

.icon-dropdown-dtr{
    /* position: absolute;
    right: 10px;
    top: 15px; */
    /* top: 12px;
    right: 30px; */
    position: absolute;
    top: 38px;
    right: 80px;
    color: #aea9a987;
    /* font-stretch: expanded;
    transform: scaleY(0.5);
    transform-origin: 0 0; */
    /* background-color: red; */
}