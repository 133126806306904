.log-title {
    width: 150px !important;
}
.changelogs-tr:hover {
    cursor: pointer;
}

.modal-main {
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 40% !important;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.changeSchedulePOV{
  background-color: #3acfdf2a ;
  padding: 10px;
  padding-left: 20px;
  border-radius: 6px;
}

.toggle-outlook{
  background-color: #105396 !important;
  border-color: #888 !important;
  margin-left: 1rem !important;
  margin-bottom: 1rem !important;
}

.timezone-row{
  
  /* margin-bottom: 2rem !important;
  margin-top: 2rem !important; */
}