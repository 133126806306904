
.report-navigator { margin-left: 10px; min-width: 625px;position: relative;top:0px;padding:0 !important;}
.report-navigator .view-navigate{font-size:2.5em; margin-left: 5px; margin-right: 5px; cursor: pointer;}
.report-navigator .navigator{margin-left: 8px; margin-right:8px;color: #82af13; position: relative; top:-4px;height: 32px}
.report-navigator .dates-label{margin-top: 4px;}
.report-navigator .view-type-tabs .nav-tabs{border: 0 !important;}
.report-navigator .view-type-tabs a{padding-bottom: 10px; margin-bottom: 0 !important; border:0 !important; background: #ccc; color:#333 !important; margin-right: 2px;}
.report-navigator .view-type-tabs a.active {background-color: #82af13  !important; color: #fff !important;}
.report-navigator .view-type-tabs button.active a{color:#82af13 !important;}
.report-navigator .react-datepicker-wrapper{position: relative;top: -4px}
.report-navigator .custom-filter-btn{position: relative;top: -4px}
