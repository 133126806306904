span .nav-clock{
    color :white
}
.nav-item .time-info{
    
    padding-right: 15px !important;
    padding-left: 10px !important;
    /* min-width: 20px !important;; */
    /* border: #fff;
    border-width: thin; */
  
}


.nav-item .time-info:hover{
    /* box-shadow: 3px 1px 0px 0px rgba(168, 223, 187, 0.287);  */
    /* background-color:  rgba(255, 255, 255, 0.1); */
    border-radius: 5px !important;
    
}

.nav-time{
    font-size:1.0rem;
    color: #fff !important;
    /* padding-left: 33px !important; */
    /* border: #fff;
    border-width: thin; */
  
}


.nav-time-clock{
    position: absolute;
    /* padding-left: 33px !important; */
    /* border: #fff;
    border-width: thin; */
  
}

.nav-date{
    font-size:0.8rem;
    color: #fff !important;
    min-width: 120px !important;
    min-height: 18px !important;
    /* background: #ccc;
    float: none;
    overflow: hidden; */
    /* border: #fff;
    border-width: thin; */
  
}
.nav-date-item{
  float: left !important;
}

.nav-clock-button.btn{
    /* margin-top: 4px;
    margin-right: 3px; */
    font-size: 11px !important;
}

.timezone-item{
    position: absolute;
    top: 24px;
    right: 25px;
    font-size: 8.5px;
}

.timezone-item-tooltip{
    position: absolute;
    top: 33px;
    right: 28px;
    font-size: 10px;
}



a[data-tool-tip] {
    position: relative;
  }
  
a[data-tool-tip]::after {
    content: attr(data-tool-tip);
    display: block;
    position: absolute;
    background-color: #78909C !important;
    padding: 1em 3em;
    color: white;
    border-radius: 5px;
    font-size: 10px;
    top: 14px;
    right: 1px;
    white-space: nowrap;
    transform: scale(0);
    /* left: 1px; */
    /* transition:
      transform ease-out 150ms,
      bottom ease-out 150ms; */
  }
  
  a[data-tool-tip]:hover::after {
    transform: scale(1);
    /* bottom: 100%; */
    /* top: 10px; */
   
  }




/* .nav-clock-dropdown:hover .dropdown-menu{
    display: block;
} */


.nav-item .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
.nav-item.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .nav-item .tooltip:hover .tooltiptext {
    visibility: visible;
  }




.nav-clock-dropdown .dropdown-menu{
    left: auto !important;
    right: 10px;
    top: 45px;

    width: 500px;
    /* height: 100px; */
    /* padding: 50px; */
    /* border: 1px solid red; */
    /* min-height: 380px; */
    padding:    7px;
}

 
.nav-clock-dropdown .dropdown-menu form{
float: left;
}
.dropdown-menu .card-body.card-size{
    height : 180px;
}

.dropdown-menu  .dtr-notifications .card-header{border:0 !important;}
.dropdown-menu  .dtr-notifications .card-body{padding:0 !important;}
.card-text{
    margin-left: auto !important;
    padding: 5px !important;
}


.div-table-row {
    display: table-row;
    width: auto;
    clear: both;
  }
  .div-col {
    float: left !important; 
    display: table-column !important;         
 
  }
.schedule{
    float: left; 
    display: table-column;  
    width: 50%;  
    /* background-color: red; */
    display: block;
    margin: 0 auto;
    padding: 10px;
    justify-content: center;
}
.in-out{
    float: right; 
    display: table-column;    
    width: 50%;
    /* background-color: blue; */
    display: block;
    margin: 0 auto;
    padding: 10px;
    justify-content: center;
}
  @media screen and (max-width: 740px) {

    .nav-clock-dropdown .dropdown-menu{
        /* left: auto !important;
        right: 0px;
        left: 3px;
        top: 51px; */

        padding-left: 2px;
        padding-right: 2px;

        width: 95% !important;
        /* height: 90%; */

        border: 1px solid rgb(53, 19, 133);
    }
  }


.main-schedule{
    float:  left ; 
    background-color: green;
    width: 50%;
}
.flex-schedule{
    float: right ;
    background-color: palegreen;
    width: 50%;
}

.dropdown-menu .nav-link{
    color: rgb(0, 0, 0) !important;
    /* padding: 10px !important; */
    /* margin: 5px !important; */
    float: right !important;
    padding-top: 10px !important;;
    padding-right: 5px !important;;
    padding-left: 5px !important;;
    
    padding-bottom: 0px !important;


    margin-right: 2px !important;;
    /* margin-left: 2px !important;; */

    font-size: 0.75rem;
    font-weight: 700;
    
}

.dropdown-menu .nav-link.active{
    background-color: #005493 !important;
    color: #fff !important;
    
    /* padding: 10px !important; */
    /* margin: auto !important; */
    
}

.dropdown-menu .nav-link:hover{

    background-color: #005493 !important;
    color: #fff !important;
    
    /* transition: 1s; */
    
}
.nav-clock-dropdown .dropdown-toggle.btn{
    background-color: transparent !important;
    border-color: transparent !important;
    padding: 0%  !important;
    margin: 0px  !important;
}

.nav-clock-dropdown .dropdown-toggle.btn:hover{
    background-color:  rgba(255, 255, 255, 0.1);

}

#incLogs {
    color: #f70000 !important;
}