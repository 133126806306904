/* @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap'); */


.notification-panel {
    background: white;
    border-radius: 15px;
    width: 60%;
    padding: 20px;
    margin: 30px auto;
}

/* .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding: 10px 0;
    margin-bottom: 15px;
} */

/* .nav h2 span {
    background: hsl(219, 85%, 26%);
    color: #fff;
    border-radius: 5px;
    padding: 0 10px;
}

.nav button {
    background: none;
    color: hsl(219, 12%, 42%);
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.nav button:hover {
    color: hsl(219, 85%, 26%);
} */

.notification {
    display: flex;
    align-items: center;
    /* justify-content: flex-start; */
    gap: 20px;
    margin-bottom: 5px !important;
    padding: 5px 10px;
    font-size: 14px !important;
}

.unread {
    /* background: hsl(210, 60%, 98%); */
    /* background:  hsl(210deg 62.82% 86.51% / 20%); */
    background:  hsl(210deg 3.59% 67.17% / 12%);
    border-radius: 10px;
    margin-right: 5px;
    margin-left: 10px;
}

#not-with-msg {
    align-items: flex-start;
}

.not-msg {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.msg {
    margin-top: 20px;
    border: 1px solid hsl(219, 14%, 63%);
    padding: 10px;
    border-radius: 10px;
}

.msg:hover {
    cursor: pointer;
    background: hsl(205, 33%, 90%);
    transition: .4s;
}

.notification img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

#not-with-pic {
    justify-content: space-between;
}

#not-with-pic img:last-child {
    border-radius: 8px;
}

.not-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.notification .not {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    flex-direction: column;
    color: black !important;
}

.utitle {
    display: flex;
    /* align-items: flex-start; */
    /* justify-content: flex-start; */
    gap: 5px;
    width: 300px;
   
}

.notification .not p {
    flex-wrap: wrap;
}

.notification .not p span:first-child {
    color:  hsl(224, 21%, 14%);
    font-weight: bold;
}

.notification .not p span:first-child:hover {
    cursor: pointer;
    color: hsl(219, 85%, 26%);
}

.notification .not p span.st {
    color: hsl(219, 85%, 26%);
    font-weight: bold;
}

.notification .not p span.st:hover {
    cursor: pointer;
}

.notification .not p span.st.dark {
    color: hsl(219, 12%, 42%);
}

.notification .not p span.st.dark:hover {
    color: hsl(219, 85%, 26%);
}


.notification .not p span.st.dark .nav-link{
    padding: 1px 0px !important;
    font-size: 13px !important;
}

.app-link:hover{
    color: hsl(219, 85%, 26%) !important;
}

.app-link{
    color: hsl(219, 12%, 42%) !important;
}

.notification .not p{

    /* margin-bottom: 1rem !important; */
    margin-bottom: 5px !important;
}

.img-chess:hover {
    cursor: pointer;
    outline: 4px solid hsl(205, 33%, 90%);
    transition: .4s;
}

.reddot {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    background: hsl(1, 90%, 64%);
    margin-left: 6px;
    border-radius: 50%;
    margin-top: 4px;
}

.attribution {
    text-align: center;
    padding: 20px;
}

@media screen and (max-width: 700px) {
    .notification-panel {
        width: 100%;
    }

    .scrollable-notifications {

        max-height: 360px !important;
      
        overflow-y: scroll !important;
    
        overflow-x: scroll !important;
    
        /* background-color: #666; */
        
      
      }
}

.app-link-height{
    height: 0px !important;
}


.row-item{
    align-items: center !important;
}

.link-line{

    text-decoration: none !important;

}