.center {
	text-align: center;
}

.modal-login-wrapper .card {
	background: transparent !important;
}

.modal-login-wrapper .card-title {
	background-color: #3a3a3a;
	color: white;
	padding: 10px;
	text-align: center;

}

.modal-login-wrapper .card-text {
	padding: 10px 40px 30px 40px
}

.modal-login-wrapper .card-text {
	padding: 0px 40px 0px 40px
}

.modal-login-wrapper .card-body {
	box-shadow: 0 1px 1px 1px rgba(9, 9, 9, 0.25) !important;
	border-bottom: 0px solid #82af13 !important;
	/* border-top: 3px solid #82af13 !important; */
	border-top: 5px solid #0097A7 !important;
	background: #fff;
}

.modal-login-wrapper .log.btn-primary {
	border-color: #82af13 !important;
	background-color: #82af13 !important;
	color: #d7f69a !important;
	font-weight: bold !important;
}

.modal-login-wrapper .card {
	margin: 8px 0;
}

.modal-login-wrapper .btn-wrapper {
	text-align: center;
}

.modal-login-wrapper .btn-primary {
	max-width: 150px;
	margin: 0 auto;
	padding: 8px 0 !important;
	min-width: 100px;
}

.modal-login-wrapper .banner {
	background: #f4f4f4;
	padding: 10px 0;
	text-align: center;
	margin-top: 20px;
	display: none
}

.modal-login-wrapper .banner p {
	margin-bottom: 5px
}

.modal-login-wrapper .banner .btn-primary {
	padding: 8px !important;
	border-radius: 4px
}

.modal-login-wrapper button:focus {
	outline: #82af13 !important;
	box-shadow: #82af13 !important;
}

.modal-login-wrapper .login_btn {
	width: 100%;
}

.modal-login-wrapper .card {
	background: transparent !important;
}

.modal-login-wrapper .col-md-5 {
	max-width: 500px !important;
	margin: 0 auto !important;
	padding: 0 !important;

}

.modal-login-wrapper .invalid-feedback {
	display: block !important;
	margin-bottom: .25rem;
}

.modal-login-wrapper .image_header {
	padding: 10px 0px 20px;
	display: block;
	margin: 0px auto !important;
	width: 150px;
}

.modal-login-wrapper .invalid_credentials {
	text-align: center;
	margin-top: 0.50rem;
}

.modal-login-wrapper .mb-3 {
	margin-bottom: 0px !important;
}

.modal-login-wrapper input:focus {
	box-shadow: 0 8px 15px 3px rgba(130, 175, 19, 0.10) !important;
	border-color: #82af13 !important;
}

.modal-login-wrapper .forgot-password-link {
	padding-top: 10px;
	padding-left: 10px;
	color: #495057 !important;
}