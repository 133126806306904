/*
 * AlertContainer css File
*/
.fadeOut{
    opacity:0;
    width:0;
    height:0;
    transition: width 0.25s 0.25s, height 0.25s 0.25s, opacity 0.25s;

}
.fadeIn{
    opacity:1;
    width:100px;
    height:100px;
    transition: width 0.15s, height 0.15s, opacity 0.15s 0.15s;

}

.alert-pop-up {
    position:fixed !important;
    top: 15%;
    width: 30%;
    left: 0;
    right: 0;
    margin: auto;
    display:block;
    max-height: 300px;
    z-index: 10000 !important;
}

.alert-container{
    position: relative !important;
}

.alert-heading {
    font-size: 1.2rem !important;
    /* text-align: center; */
}
.alert-body {
    font-size: .8rem !important;
}

.alert-body ul{
    list-style-type: none;
    /* text-align: center; */
}