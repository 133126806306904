body:not(.sidebar-mini-md) .content-wrapper, body:not(.sidebar-mini-md) .main-footer, body:not(.sidebar-mini-md) .main-header {
  /* padding-left: 1.5%; */
  padding-bottom: 1px;
  z-index: 100 !important;
}

.navbar {
  background-color: #07335e !important;
  /* background-color: #ffffff !important; */
}

a, .navbar-brand, .navbar-nav .nav-link {
color: #bbb !important;
}

.nav-link:hover{
color: white !important;
}

.navbar{
  border-bottom: 3px solid;
/* border-bottom: 5px solid #343a40 !important; */
/* border-bottom: 5px solid #82af13 !important;  */
padding-bottom: 0;

}

.header{
color: #82af13;

}
  
.header h1{
font-size: 1.2em;
}
  
.loader {
left: 50%;
margin-left: -4em;
}

a.dropdown-item{
color: black !important;
}

a.dropdown-item:hover{
background-color: #82af13;
color: white !important;
}

label:not(.form-check-label):not(.custom-file-label) {
  font-weight: normal;
  padding-right: 3%;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
  margin-right: 5px;
}

button[type=submit].btn-danger{
  color: #fff;
  background-color: #bd2130 !important;
  border-color: #b21f2d;
}

button[type=submit].btn-secondary{
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.nav-sidebar .nav-item>.nav-link {
  position: relative;
  cursor: pointer;
  display: block;
}

.has-treeview.menu-open{background: rgba(255,255,255,.1) !important}

.notifi{ 

  margin-top: 5px !important;
  margin-right: 10px !important;

}