.quickpunch{
    text-align : center;
    background-color: #07335e;
    color: white;
}
.quickpunch h3 {
    color: #fff !important;
    font-size: 2rem;
    margin-top: 20px;
}
.quickpunch .note{padding: 0 20px}
.note{
    text-align: justify;
    font-size: .875em;
}

.quickpunch .time{
    font-size: 3em;
    margin-top: 20px;
}

.quickpunch .btn {
    width: 100px;
    margin: 30px 10px 10px;
}

.quickpunch .date,.quickpunch .label {
    color: #d4d4d4;
}
.request-rest-day-link {
    padding: 5px !important;
    background-color: #82af13 !important;
    color: #fff !important;
}