.icon-column {
    width: 10%;
    border-right: 1px solid white !important;
    text-align: center;
}

.details-column {
    width: 50%;
    border-left: 1px solid white !important;
    border-right: 1px solid white !important;
}
.note-column {
    width: 40%;
    border-left: 1px solid white !important;
}

.time-off-icon {
    width: 50px;
    height: 50px;
}
.leave-row {
    border-bottom: 1px solid #cccccc !important;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-left: 10px !important;
    margin-right: 10px !important;
}
.no-leaves-row{
    text-align: center;
    margin-top: 40px;
}

.time-off-date {
    font-size: 17.5px;
}


.fa-icon {
    color: #82af13;
    font-size: 50px;
}
.custom-date {
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    width: 170px !important;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.custom-filter-btn{
    height: calc(1.5em + .75rem + 2px)
}