.emp_sched .card-body{padding:2px !important; margin-left:2px !important; margin-right:2px !important;border-top:1px solid #ccc !important;}
.emp_sched .card{margin-bottom: 5px !important}
.cl{clear:both;}
div.schedule_info {
    font-size: .875em;
    text-align: center;
}
div.schedule_info div {
    font-size: .875em;
}
div.schedule_info div:nth-child(2) {
    font-size: 10px;
}
.report-schedule .card-header{display: none}
.report-schedule .navigator-bar{margin-bottom: 0 !important}
.emp_sched .rest_day,
.legends .rest_day .box{
    background-color: #d7d7d7;
    color: black !important;
}

.header_text{
    margin-left: 1.3rem !important;
}

.emp_sched .holiday,
.legends .holiday .box{
    background-color: #b1faff !important;
    color: black !important;
}
.emp_sched .absent,
.legends .absent .box{
    background-color: #fd2b1d;
    color: #fff !important;
}
.today-sched .emp_sched div:nth-child(2){background: #eee; line-height: 130%}
.emp_sched .late,
.legends .late .box{
    background-color: #fffe00;
    color: black !important;
}

.emp_sched .on_leave,
.legends .on_leave .box{
    background-color: #17a2b8;
    color: #fff !important;
}

.emp_sched .undertime,
.legends .undertime .box{
    background-color: #ffb700;
    color: black !important;
}

.emp_sched .early,
.legends .early .box{
    background-color: #c5e86e;
    color: black !important;
} 

.emp_sched .no_status,
.legends .no_status .box{
    background-color: #eee;
    color: black !important;
}
.Hourframe{margin:0 !important;padding-top: 10px; clear:both;}

.Hourframe div {
    width: 4%;
    font-size:.75em;
    border-left:1px solid #999;
}
.Hourframe div span{position:relative;top:-14px; left:-10px; }

.emp_sched{
    margin: 5px;
    margin: 2px 0 !important;
    overflow: hidden;
}
.emp_sched span{display: block;padding:3px 5px;font-size:.75em;}
.today-sched .emp_sched{height: 20px;}
.calendar-sched{clear: both;}
.viewmore {background: rgba(0,0,0,.25) !important;width: 100%;left:0; padding:10px 0;bottom: 0; position: fixed;z-index: 2000; text-align: center;}
.viewmore button{width: 100px; border: 0; text-align: center;margin:0 auto;}
.legends {margin-bottom: 20px;float: right;}
.legends div{margin-right: 10px;font-size:10px}
.legends .box {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 2px;
    border: 1px solid #ccc;
    position: relative;
    top:2px;
}
.legends .status{font-size: 10px; color: #666}
div.days{background: #005493; margin:0;padding: 0 !important}
div.days div{text-align: center; text-transform: uppercase; color:#fff;padding:2px; border:1px solid #fff}
.schedule-col{height: 30px}
.schedule-date{font-weight: bold; text-transform: uppercase; font-size: .875em; text-align:center;}
.schedule-holiday{font-size: .67em;text-align:center;margin-top: -2px;color: #000;}
.emp_sched .row{
    padding-bottom: 20px;
}

.emp_sched .col .employee_list{
    max-height: 250px;
    overflow-y: auto; 
}
.emp_sched ::-webkit-scrollbar {
    height: 4px;              /* height of horizontal scrollbar ← You're missing this */
    width: 4px;               /* width of vertical scrollbar */
    border: 1px solid #d5d5d5;
  }

.emp_sched ::-webkit-scrollbar-track { 
border-radius: 0;
background: #eeeeee;
}

.emp_sched ::-webkit-scrollbar-thumb {
border-radius: 0;
background: #b0b0b0;
}