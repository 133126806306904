/*
 * Sidebar css File
*/
.brand-link-color {
  background-color: #07335e;
}

.sidebar {
  box-shadow: none !important;;
}

.brand-text  {
/* font-family: "Roboto",Helvetica,Arial,Lucida,sans-serif; */
font-size: 20px !important;
padding-left: 10px !important;
}
.brand-text b.green {
  color: #9be10d;
}
.main-sidebar{
  background-color: #ffffff;
  position: fixed !important}
.sidebar{height: 90vh; overflow-y: auto}
.sidebar .mt-2{margin-left:1rem;}
.activeAttendanceSummaryReport {
    background-color: rgb(255,255,255,.1);
    color: rgb(123, 23, 23)
}
.main-sidebar p, .main-sidebar i{
  /* background-color: lightgrey; */
  color: #000000
}

.admin-sidebar.main-sidebar p, .admin-sidebar.main-sidebar i{
  /* background-color: lightgrey; */
  color: #575A89
}



.main-sidebar .nav-item:hover{
  background-color: #005493;
  border-radius:  3px 3px 3px 3px;
}

/* .main-sidebar .nav-item:hover.has-treeview i, .main-sidebar .nav-item:hover.has-treeview p{
  color: #82af13;
  border-radius:  3px 3px 3px 3px;
} */

.main-sidebar .nav-item:hover:not(.has-treeview.menu-open) i, .main-sidebar .nav-item:hover:not(.has-treeview.menu-open) p{
  color: #ffffff;
  border-radius:  3px 3px 3px 3px;
}


.main-sidebar .nav-treeview{

    /* margin-left: 15px; */
}


/* .main-sidebar .admin-sidebar .nav-item:hover{
  background-color: #575A89;
  border-radius: 3px 3px 3px 3px;
} */

.brand-text {
  /* background-color: lightgrey; */
  color: white;
}

.d-block-new {
  /* background-color: lightgrey; */
  color: white;
}
/* width */
::-webkit-scrollbar {
  width:8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px #ccc; 
  border-radius: 4px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff; 
}

.has-treeview.menu-open {
  /* background: #0096a735 !important; */
}

.has-treeview.menu-open .nav-item{

  margin-left: 7px !important;

}
.has-treeview.menu-open.admin-sidebar {
  /* background: #6469d37c !important; */
  
}
.has-treeview.menu-open .nav-link-main {
  background:  #005493!important;
 
  border-radius: 3px 3px 3px 3px;
}

.has-treeview.menu-open .nav-link-main  p, .has-treeview.menu-open .nav-link-main  i  {
 color: #ffffff !important;
}



.icon-stack {
  position: relative;
  display: inline-block;
  width: 1.8em;
  height: 2em;
  line-height: 3em;
  vertical-align: middle;
  margin-right: 5px !important;
  margin-left: 2px !important;
}
.icon-stack-1x,
.icon-stack-2x,
.icon-stack-3x {
  position: absolute;
  left: 0;
  width: 100%;
    text-align: center !important;
}
.icon-stack-1x {
  line-height: inherit;
  position: absolute;
  /* left:8px; */
  top:-5px;
  /* text-align: left !important; */
}
.icon-stack-2x {
  font-size: 1.6em;
}
.icon-stack-3x {
  font-size: 1.8em;
}


.icon-stack-1x2 {
  line-height: inherit;
  position: absolute;
  /* left:8px; */
  top:-5px;
  /* text-align: left !important; */
  font-size: .7rem !important;
}

.icon-stack-1x-BL {

  position: absolute;

  left: -9px;

  right: 0;

  top: 1px;

}

.icon-stack-green {

  color: green;

}


.icon-stack-yellow {

  color: yellow;

}


.icon-stack-red {

  color: red;

}