.lab {
    float: left;
    font-size: 10px;
    padding: 9px 24px 8px;
    text-transform: uppercase;
    background: #ccc;
}

.tableheader{
    text-align: center;
    vertical-align: middle !important;
    background: #82af13 !important;
    color: #fff !important;
}

.newhire{

    background-color: #82af13;
    text-align: left;
    font-size: 10px !important;
    font-weight: 700;
    color: #fff;
}