.ev-color{
    color: #82af13 !important;
}
.sortby {
    display: block !important;
    margin: 0;
    position: relative;
    top: 28px;
    z-index: 1000;
}
td.actions .fa{margin-right: 5px;}

.sortby > div
 {
    float: right;
    max-width: 250px;
    flex: unset;
    margin-right: 0px;
}
.sortby label {
    float: left;
    background: #ccc;
    padding: 6px;
    display: inline-block;
}
.sortby .form-group {
    float: left;
    max-width: 150px;
    margin-right: 0px;
}
.sortby label{
    padding: 7px;
}
.sortby select{
    border-radius: 0;
}
.sortby .btn {
    float: left;
    max-width: 100px;
}
.department-filter>div{
    min-height: 32px !important;
}
.department-filter>div>div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.department-filter>div>div:nth-child(2) div {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}