.page-img{
    width: 100%;
}

.announcement-content-page{
    margin: 1% 1% 0;
}

.page-img{
    /* box-shadow:  0 3px 9px 0 rgba(0, 0, 0, 0.067); */
}
.page-content{
    margin-top: 27px;
    min-height: 250px;
}

.page-content > p > iframe{
  width: 100% !important;
  margin-bottom: 10px !important;
}

/* .page-content  p:has(iframe) {

    position: relative !important;

    padding-bottom: 56.25%  !important; 

    height: 0 !important;

} */

/* .page-content  p  iframe {

position: absolute  !important;

top: 0  !important;

left: 0 !important;

width: 100% !important;

height: 100% !important;
} */

.page-content-title{
    /* margin-top: 15px; */
    font-size: 42px !important;
    font-weight: bolder !important;
}

.page-content-info{
    font-size: 14px !important;
    color: rgba(37, 47, 87, 0.579);
    margin-bottom: 15px;
}

.black-card-text{

    color:black !important;
    
}
.black-card-text-bigger{
    color:black !important;
    font-size: 18px !important;
    text-transform: uppercase !important;
}
/* 
.youtube-video {
    aspect-ratio: 16 / 9;
    width: 50%;
  } */