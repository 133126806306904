.announcement-figure-cap{
    position: absolute;
    bottom: 4.5rem;
    font-size:16px !important;
    font-weight: 600;
    margin: 0 1rem;
}


.announcement-figure-img{
    width: 100% !important; height: 300px !important; object-fit: cover !important;
}

.announcement-figure.figure{
 border-radius: 10px !important;
}

.announcement-list-card.card{
    border-radius: 10px !important;
    margin-bottom: 18px;


    

}
.announcement-list-card.card.on-manager{
    height: 100% !important;
    padding-bottom: 20px !important;

    

}

.announcement-list-item{
   background-color: rgb(229, 229, 229);
   border-radius:   10px !important;
   
}

.mask-HR{
    background-color: rgba(23, 149, 73, 0.434) !important;
    /* position: absolute !important;
    bottom: 0 !important;
    left: 0 !important; */
}

.mask-Department{
    background-image: linear-gradient(180deg, rgba(42, 42, 43, 0) 50%, rgba(28, 28, 29, 0.733));
    /* background-color: rgba(0, 83, 147, 0.498) !important; */
    /* position: absolute !important;
    bottom: 0 !important;
    left: 0 !important; */
}

.mask-HR.card-img-overlay,.mask-Department.card-img-overlay{
    padding-top: 162px   !important;
    /* vertical-align: bottom; */
    /* padding-bottom: 0; */
    
}

.mask-HR.card-img-overlay .card-title, .mask-Department.card-img-overlay .card-title{
   /* margin-top: auto !important; */
}
.card-text-black{
    color:black
}

.card-text-overflow{
    
    width: 100%;
    height:4rem !important;
    overflow: hidden;
    text-overflow: ellipsis;
 
}

.card-text-white,.card-text-white.h5 ,.card-title.card-text-white.h5{
    color:rgb(255, 255, 255) !important;
    text-shadow: 1px 1px #000000;
    font-weight: bold !important;
    text-align: left !important;

}
.announcement-list-content.dashbaord-content.card-content{
    padding-top: 10px !important;
}

.card-bottom-content{
    padding-left: 8px !important;
    padding-bottom: 1px  !important;
    padding-right: 1px  !important;
}

/* .tag-badge{
    color: #000000;
    background-color: #8FD402;
    font-size: 11px !important;
} */

/* .announcement-tabs .nav-item{
    color:rgba(0, 83, 147) !important;
} */

.show-more-dashboard{
    margin-top: 12px !important;
    width: 60% !important;
    background-color: rgba(0, 83, 147) !important;
}

button[disabled=disabled].show-more-dashboard, button:disabled.show-more-dashboard {
    background-color:rgb(110, 159, 196) !important;
}


.dashboard-dep-select{
    /* min-width: 100% ; */
    max-width: 300px ;
}