

#forgotPasswordRequest .card-title{
	background-color: #3a3a3a;
	color: white;
	padding: 10px;
	text-align: center;

}

#forgotPasswordRequest .card-text{
	padding: 0px 30px 10px 30px
    }

#forgotPasswordRequest .card-body{
    box-shadow: 0 8px 15px 3px rgba(0,0,0,0.25) !important;
    border-bottom: 5px solid #82af13 !important;
    /* border-top: 5px solid #82af13 !important; */
	border-top: 5px solid #0097A7  !important;
}

#forgotPasswordRequest .btn-primary {
	border-color: #82af13 !important;
    background-color: #82af13 !important;
}
 
#forgotPasswordRequest button:focus {
 	outline: #82af13 !important;
  	box-shadow: #82af13 !important;
}

#forgotPasswordRequest .close_btn {
	width: 100%;
}

#forgotPasswordRequest .col-md-5{
	max-width: 600px !important;
	margin: 100px auto !important;

}

#forgotPasswordRequest .invalid-feedback{
	display : block !important;
	margin-bottom: .25rem;
}

#forgotPasswordRequest .image_header{
	padding: 50px 0px;
    display: block;
    margin: 0px auto !important;
}

#forgotPasswordRequest body{
	background-color: #f4f4f4 !important;
	background-image: url(https://evox.eastvantage.com/sites/all/themes/evox_style/img/bg.jpg);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-attachment: fixed;
}

#forgotPasswordRequest .invalid_credentials{
	text-align: center;
    margin-top: 0.50rem;
}

#forgotPasswordRequest .mb-3{
	margin-bottom: 0px!important;
}

#forgotPasswordRequest input:focus
{
	box-shadow: 0 8px 15px 3px rgba(130, 175, 19, 0.10) !important;
    border-color : #82af13 !important;
}

#forgotPasswordRequest .powered_by{
	font-size: 14px;
	margin-top: 5px; 
	margin-right: 2.5px; 
	float: right;
}

.min-vh-80{
	min-height: 80vh!important;
}

#forgotPasswordRequest .approved_status {
	color: #82af13;
}
#requestEmailApproval .declined_status {
	color: #eb2626;
}

#forgotPasswordRequest p{
	font-size: 20px;
    text-align: center;
}

#forgotPasswordRequest .back-to-login-btn{
	color: white !important;
}

.forgot-password-header {
	color: black;
}