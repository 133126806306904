.punch-bullet{
    list-style-type: none;
  margin: 0;
  padding: 0;
}

.punch-bullet-2{
  list-style-type: none;
margin: 0;
padding: 0;
margin-left: 10px;
}


.punch-row{
    color: #000;
    font-size: 1rem;
    margin: 0;
    font-weight: 600;
}