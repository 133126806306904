body{font-size: 11px}
.card {background: transparent !important; }
.card-title{
	background-color: #3a3a3a;
	color: white;
	padding: 10px;
	text-align: center;

}

.card-text{
	padding: 10px 40px 30px 40px
    }

.card-body{
    box-shadow: 0 1px 1px 1px rgba(9,9,9,0.25) !important;
    border-bottom: 0px solid #82af13 !important;
    border-top: 3px solid #0097A7  35e !important;
    background: #fff;
}

.btn-primary {
	border-color: #82af13 !important;
    background-color: #82af13 !important;
}
.login-wrapper .btn-wrapper {text-align: left;
}
.login-wrapper .btn-primary {
    max-width: 150px;
    margin: 0 auto;
    padding: 8px 0 !important;
    min-width: 100px;
}
 
button:focus {
 	outline: #82af13 !important;
  	box-shadow: #82af13 !important;
}

.login_btn {
	width: 100%;
}

.login-wrapper .card {background: transparent !important; }
.login-wrapper .col-md-5{
	max-width: 500px !important;
	margin: 100px auto !important;

}

.invalid-feedback{
	display : block !important;
	margin-bottom: .25rem;
}

.image_header{
	padding: 10px 0px 20px;
    display: block;
    margin: 0px auto !important;
    width: 150px;
}

body{
	background-color: #f4f4f4 !important;
	/*background-image: url(https://eastvantage.com/evox/icn/bg.jpg);
    */background-repeat: no-repeat;
    background-position: bottom right;
    background-attachment: fixed;
}

.invalid_credentials{
	text-align: center;
    margin-top: 0.50rem;
}

.mb-3{
	margin-bottom: 0px!important;
}

input:focus
{
	box-shadow: 0 8px 15px 3px rgba(130, 175, 19, 0.10) !important;
    border-color : #82af13 !important;
}

.powered_by{
	font-size: 11px;
	margin-top: 5px; 
	margin-right: 2.5px; 
	float: right;
}

.min-vh-80{
	min-height: 80vh!important;
}

.forgot-password-link{
    padding-top: 10px;
    padding-left:10px;
	color: #495057 !important;
}

@media only screen and (min-width: 1400px) {body{font-size:1rem;}}

@media only screen and (max-width: 600px) {
.dashboard .col-8,.dashboard .col-6,.dashboard .col-4{flex: 0 0 100%; max-width: 100%}
.quickpunch .btn {
    margin: 10px 10px;
}

}