
.btn-success {
    color: #fff !important;
    background-color: #82af13 !important;
    border-color: #82af13 !important;
}


.announcement-list-img.card-img-top{ 
    background-color: #ababab;
    /* width: 50%;
    height: auto !important; */
    width: 100%; height: 250px; object-fit: cover;
    margin: auto;
    display: block;
    
}

.announcement-list-content .card-title.h5{ 
    /* background-color: #ababab; */
    font-size: 18px !important;
    
}

.announcement-list-content .card-text{ 
    font-size: 15px !important;
    
}

.btn.btn-primary.create-announcement{
    margin-bottom:20px;
}

.manage-announcement-option.ann-admin {
    position: absolute;
    right:    0;
    bottom:   30px;
   }

.manage-announcement-option .btn{
 margin-right: 3px!important;
}

.announcement-all-desc{
    padding:15px 20px 5px 20px;
    background-color: rgb(199, 222, 239) !important;

    border-radius: 12px !important;
    margin-bottom: 10px;
    font-size: 13px !important;

    
}

.create-announcement-card{
    /* background-color:  #005493 !important; */
    width: 100%;
    min-height:408px;
    padding-top:130px;
    display: block;
    text-align: center;
    color: #74a80a !important;
    box-shadow: 0 1px 1px 1px rgba(9,9,9,0.25) !important;
}
.i-create-ann{
    font-size: 85px !important;
}
.ongoing{
    color: #789b21;
    background-color: rgb(162, 207, 241);
    border-radius: 5px;
    padding: 2px;
    width: 50px;
    margin-bottom: 5px;
    
}

.expired{
    color: red;
    background-color: rgb(236, 219, 219);
    border-radius: 5px;
    padding: 2px;
    width: 50px;
    margin-bottom: 5px;
}