.ops-sched-btn {
    background-color: transparent !important;
    border-color: transparent !important;
    font-size: 18px !important;
}

.ops-sched-btn:hover {
    background-color: #0069d9 !important;
    border-color: #0069d9 !important;
}

.ta-center {
    text-align: center !important;
}