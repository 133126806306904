.btn-primary-2{
    border-color: #025da3 !important;
background-color: #005493 !important;
/* font-weight: 500 !important; */
}

.btn-primary-3{
    border-color: #e1eaae !important;
background-color: #e1eaae !important;
color: black !important;
/* font-weight: 500 !important; */
}


i.is-green{
    color: #b3fa25
    !important;
}